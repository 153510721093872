import React from "react"

import { useVideoOptimization } from "../../../hooks/contentful"
import { ContentfulSocialProofVideoEntryFieldsProps } from "../../../hooks/useLandingContentfulQuery"
import {
  SocialProofCardContainer,
  SocialProofCardVideo,
} from "./LandingSocialProofCard.styled"

const LandingSocialProofCardVideo = React.memo(
  (props: ContentfulSocialProofVideoEntryFieldsProps) => {
    const { video } = props
    const videoOptimized = useVideoOptimization(video.url)

    return (
      <SocialProofCardContainer>
        <SocialProofCardVideo
          loop
          muted
          autoPlay
          playsInline={true}
          width={video.width}
          height={video.height}
          source={videoOptimized || video.url}
          type={video.mimeType}
        />
      </SocialProofCardContainer>
    )
  }
)

export { LandingSocialProofCardVideo }
