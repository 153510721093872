import { Box, Typography, styled, useTheme } from "decentraland-ui2"

const SocialProofSection = styled("section")({
  width: "100%",
  padding: 0,
  margin: 0,
  marginBottom: "150px",
  position: "relative",
  minHeight: "600px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  zIndex: 15,
})

const SocialProofContainer = styled(Box)(() => {
  const theme = useTheme()
  return {
    marginLeft: "160px",
    marginRight: "160px",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "80px",
      marginRight: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "16px",
      marginRight: "16px",
    },
  }
})

const SocialProofTitle = styled(Typography)({
  marginBottom: "48px",
})

export { SocialProofSection, SocialProofContainer, SocialProofTitle }
