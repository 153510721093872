import React, { useCallback } from "react"

import { useInView } from "react-intersection-observer"

import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp"
import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import env from "decentraland-gatsby/dist/utils/env"
import {
  Desktop,
  TabletAndBelow,
  useMobileMediaQuery,
} from "decentraland-ui/dist/components/Media/Media"

import {
  isWebpSupported,
  useImageOptimization,
  useVideoOptimization,
} from "../../../hooks/contentful"
import { SectionViewedTrack } from "../../../modules/segment"
import { DownloadButton } from "../../Buttons/DownloadButton"
import {
  Hero,
  HeroActionsContainer,
  HeroChevronContainer,
  HeroContainer,
  HeroContent,
  HeroContentLoading,
  HeroImageContainer,
  HeroSection,
  HeroSubtitle,
  HeroTextContainer,
  HeroTitle,
  HeroVideo,
} from "./LandingHeroV2.styled"
import { LandingHeroV2Props } from "./LandingHeroV2.types"

const LandingHeroV2 = React.memo((props: { hero: LandingHeroV2Props }) => {
  const {
    title,
    subtitle,
    imageLandscape,
    videoLandscape,
    imagePortrait,
    videoPortrait,
    buttonPrimaryLabel,
    id,
  } = props.hero

  const isMobile = useMobileMediaQuery()

  const handleMainCTA = useTrackLinkContext()

  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      handleMainCTA(event)
      const href = event.target.href
      setTimeout(() => {
        window.location.href = href
      }, 500)
    },
    [handleMainCTA]
  )

  const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

  const imageLandscapeOptimized = useImageOptimization(imageLandscape.url)
  const imagePortraitOptimized = useImageOptimization(imagePortrait.url)
  const videoLandscapeOptimized = useVideoOptimization(videoLandscape?.url)
  const videoPortraitOptimized = useVideoOptimization(videoPortrait?.url)

  return (
    <HeroSection>
      <HeroContainer ref={sectionInView}>
        <Hero data-index={id}>
          <HeroTextContainer>
            <HeroTitle variant="h1">{title}</HeroTitle>
            <HeroSubtitle variant="h3" sx={{ typography: { sm: "h4" } }}>
              {subtitle.text}
            </HeroSubtitle>

            <HeroActionsContainer>
              <DownloadButton
                href={env("DOWNLOAD_URL")!}
                onClick={handleClick}
                label={buttonPrimaryLabel!}
                place={SectionViewedTrack.LandingHero}
                isFullWidth={isMobile}
              />
              <TabletAndBelow>
                <HeroChevronContainer>
                  <ExpandMoreSharpIcon fontSize="inherit" />
                </HeroChevronContainer>
              </TabletAndBelow>
            </HeroActionsContainer>
          </HeroTextContainer>
          <HeroContent>
            <HeroContentLoading />
            <Desktop>
              {videoLandscape && (
                <HeroVideo
                  loop
                  muted
                  autoPlay
                  playsInline={true}
                  width={videoLandscape.width}
                  height={videoLandscape.height}
                  poster={
                    (isWebpSupported() && imageLandscapeOptimized.webp) ||
                    imageLandscapeOptimized.jpg ||
                    imageLandscapeOptimized.optimized
                  }
                  isInView={isSectionInView}
                  source={videoLandscapeOptimized || videoLandscape.url}
                  type={videoLandscape.mimeType}
                />
              )}
              {imageLandscape && (
                <HeroImageContainer
                  imageUrl={
                    (isWebpSupported() && imageLandscapeOptimized.webp) ||
                    imageLandscapeOptimized.jpg ||
                    imageLandscapeOptimized.optimized
                  }
                />
              )}
            </Desktop>
            <TabletAndBelow>
              {videoPortrait && (
                <HeroVideo
                  loop
                  muted
                  autoPlay
                  playsInline={true}
                  width={videoPortrait.width}
                  height={videoPortrait.height}
                  poster={
                    (isWebpSupported() && imagePortraitOptimized.webp) ||
                    imagePortraitOptimized.jpg ||
                    imagePortraitOptimized.optimized
                  }
                  isInView={isSectionInView}
                  source={videoPortraitOptimized || videoPortrait.url}
                  type={videoPortrait.mimeType}
                />
              )}
              {imagePortrait && (
                <HeroImageContainer
                  imageUrl={
                    (isWebpSupported() && imagePortraitOptimized.webp) ||
                    imagePortraitOptimized.jpg ||
                    imagePortraitOptimized.optimized
                  }
                />
              )}
            </TabletAndBelow>
          </HeroContent>
        </Hero>
      </HeroContainer>
    </HeroSection>
  )
})

export { LandingHeroV2 }
