import React from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import {
  preloadAs,
  preloadType,
} from "decentraland-gatsby/dist/components/Head/Preload"
import useFeatureFlagContext from "decentraland-gatsby/dist/context/FeatureFlag/useFeatureFlagContext"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Loader } from "decentraland-ui/dist/components/Loader/Loader"
import { Desktop, TabletAndBelow } from "decentraland-ui2/dist/components/Media"

import { LandingBannerCTA } from "../components/Landing/LandingBannerCTA"
import { LandingFooterV2 } from "../components/Landing/LandingFooterV2"
import { LandingHeroV2 } from "../components/Landing/LandingHeroV2"
import { LandingMissionsV2 } from "../components/Landing/LandingMissionsV2"
import { LandingSocialProof } from "../components/Landing/LandingSocialProof"
import { LandingTextMarquee } from "../components/Landing/LandingTextMarquee"
import { LandingWhatsHot } from "../components/Landing/LandingWhatsHot"
import Layout from "../components/Layout/Layout"
import useLandingContentfulQuery from "../hooks/useLandingContentfulQuery"
import { SegmentEvent } from "../modules/segment"

import "./index.css"

export enum Feed {
  Scenes,
  Events,
  Adventures,
  Multiplayer,
}

export default function IndexPage(props: any) {
  const l = useFormatMessage()

  const [ff, { loaded: featureFlagsLoaded, loading: featureFlagsLoading }] =
    useFeatureFlagContext()

  const landingContentful = useLandingContentfulQuery()

  if (!featureFlagsLoaded || featureFlagsLoading) {
    return <Loader active size="large" />
  }

  return (
    <Layout
      fluid
      hideSocialLinks
      {...props}
      className={TokenList.join([props.className, "layout__navbar--fixed"])}
    >
      <Head
        title={l("index.social.title") || ""}
        description={l("index.social.description") || ""}
        image={landingContentful.herov2.imageLandscape.url}
      >
        <Desktop>
          <>
            <link
              rel="preload"
              href={landingContentful.herov2?.imageLandscape.url}
              as={
                preloadAs(landingContentful.herov2?.imageLandscape.url) ||
                "image"
              }
              type={preloadType(landingContentful.herov2?.imageLandscape.url)}
            />
            <link
              rel="preload"
              href={landingContentful.herov2.videoLandscape.url}
              as={
                preloadAs(landingContentful.herov2?.videoLandscape.url) ||
                "video"
              }
              type={preloadType(landingContentful.herov2?.videoLandscape.url)}
            />
          </>
        </Desktop>
        <TabletAndBelow>
          <>
            <link
              rel="preload"
              href={landingContentful.herov2?.imageLandscape.url}
              as={
                preloadAs(landingContentful.herov2?.imageLandscape.url) ||
                "image"
              }
              type={preloadType(landingContentful.herov2?.imageLandscape.url)}
            />
            <link
              rel="preload"
              href={landingContentful.herov2.videoLandscape.url}
              as={
                preloadAs(landingContentful.herov2?.videoLandscape.url) ||
                "video"
              }
              type={preloadType(landingContentful.herov2?.videoLandscape.url)}
            />
          </>
        </TabletAndBelow>
      </Head>
      <LandingHeroV2 hero={landingContentful.herov2} />
      <LandingMissionsV2 missions={landingContentful.missionsv2} />
      <LandingBannerCTA
        {...landingContentful.createAvatarBanner}
        eventPlace={SegmentEvent.CreateYourAvatar}
      />
      <LandingWhatsHot list={landingContentful.whatsHot.list} />
      <LandingTextMarquee text={landingContentful.textMarquee.text} />
      <LandingSocialProof socialProof={landingContentful.socialProof} />
      <LandingBannerCTA
        {...landingContentful.startExploringBanner}
        eventPlace={SegmentEvent.GoToExplorer}
      />
      <LandingFooterV2 />
    </Layout>
  )
}
