import React from "react"

import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"

import { ImageList } from "decentraland-ui2"

import { ContentfulSocialProofListProps } from "../../../hooks/useLandingContentfulQuery"
import {
  SocialProofContainer,
  SocialProofSection,
  SocialProofTitle,
} from "./LandingSocialProof.styled"
import { LandingSocialProofCardImage } from "./LandingSocialProofCardImage"
import { LandingSocialProofCardQuote } from "./LandingSocialProofCardQuote"
import { LandingSocialProofCardVideo } from "./LandingSocialProofCardVideo"

const LandingSocialProof = React.memo(
  (props: { socialProof: ContentfulSocialProofListProps }) => {
    const { list } = props.socialProof

    const l = useFormatMessage()

    return (
      <SocialProofSection>
        <SocialProofTitle variant="h3">
          {l("component.landing.social_proof.title")}
        </SocialProofTitle>
        <SocialProofContainer>
          <ImageList
            variant="masonry"
            cols={2}
            gap={20}
            sx={{
              columnCount: {
                md: "3 !important",
                lg: "4 !important",
                xl: "5 !important",
              },
            }}
          >
            {list.map((cardProps, key) => {
              switch (cardProps.type) {
                case "image":
                  return (
                    <LandingSocialProofCardImage key={key} {...cardProps} />
                  )
                case "video":
                  return (
                    <LandingSocialProofCardVideo key={key} {...cardProps} />
                  )
                case "quote":
                  return (
                    <LandingSocialProofCardQuote key={key} {...cardProps} />
                  )
              }
            })}
          </ImageList>
        </SocialProofContainer>
      </SocialProofSection>
    )
  }
)

export { LandingSocialProof }
