import { Box, styled } from "decentraland-ui2"

const MissionsSection = styled("section")(() => {
  return {
    width: "100%",
    padding: 0,
    margin: 0,
    position: "relative",
  }
})

const MissionsContainer = styled(Box)({
  height: "100%",
  /* 
    TODO: to review: do we need this? this introduce an error
    overflow: "hidden",
    width: "100%",
    position: "relative", 
  */
})

export { MissionsSection, MissionsContainer }
