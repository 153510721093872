import React from "react"

import { useInView } from "react-intersection-observer"

import { ContentfulTextMarqueeEntry } from "../../../hooks/useLandingContentfulQuery"
import {
  TextMarqueeContainer,
  TextMarqueeSection,
  TextMarqueeTitle,
  TextMarqueeTitleSecond,
} from "./LandingTextMarquee.styled"

const LandingTextMarquee = React.memo(
  (props: Pick<ContentfulTextMarqueeEntry, "text">) => {
    const { text } = props

    const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

    return (
      <TextMarqueeSection>
        <TextMarqueeContainer ref={sectionInView}>
          <TextMarqueeTitle>{text.text}</TextMarqueeTitle>
          <TextMarqueeTitleSecond>{text.text}</TextMarqueeTitleSecond>
        </TextMarqueeContainer>
      </TextMarqueeSection>
    )
  }
)

export { LandingTextMarquee }
