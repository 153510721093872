import React, { useCallback } from "react"

import { useInView } from "react-intersection-observer"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import {
  Desktop,
  TabletAndBelow,
} from "decentraland-ui/dist/components/Media/Media"

import {
  isWebpSupported,
  useImageOptimization,
  useVideoOptimization,
} from "../../../hooks/contentful"
import { BannerButton } from "../../Buttons/BannerButton"
import {
  BannerCTAActionsContainer,
  BannerCTAContainer,
  BannerCTAContent,
  BannerCTAImageContainer,
  BannerCTASection,
  BannerCTASubTitle,
  BannerCTATextContainer,
  BannerCTATextWrapper,
  BannerCTATitle,
  BannerCTAVideo,
} from "./LandingBannerCTA.styled"
import { BannerCTAProps } from "./LandingBannerCTA.types"

const LandingBannerCTA = React.memo((props: BannerCTAProps) => {
  const {
    title,
    subtitle,
    imageLandscape,
    videoLandscape,
    imagePortrait,
    videoPortrait,
    buttonLabel,
    url,
    textPosition,
    titlePosition,
    id,
    eventPlace,
  } = props

  const handleMainCTA = useTrackLinkContext()

  const handleClick = useCallback(
    (event) => {
      event.preventDefault()
      handleMainCTA(event)
      const href = event.target.href
      setTimeout(() => {
        window.location.href = href
      }, 500)
    },
    [handleMainCTA]
  )

  const [sectionInView, isSectionInView] = useInView({ threshold: 0.1 })

  const imageLandscapeOptimized = useImageOptimization(imageLandscape.url)
  const imagePortraitOptimized = useImageOptimization(imagePortrait.url)
  const videoLandscapeOptimized = useVideoOptimization(videoLandscape?.url)
  const videoPortraitOptimized = useVideoOptimization(videoPortrait?.url)

  return (
    <BannerCTASection>
      <BannerCTAContainer
        ref={sectionInView}
        isCentered={textPosition === "center"}
        data-index={id}
      >
        <BannerCTATextContainer
          isCentered={textPosition === "center"}
          isInView={isSectionInView}
        >
          <BannerCTATextWrapper>
            {titlePosition === "first" && (
              <BannerCTATitle isPositionFirst variant="h1">
                {title}
              </BannerCTATitle>
            )}
            <BannerCTASubTitle
              isPositionFirst={titlePosition !== "first"}
              variant={titlePosition === "first" ? "h4" : "h3"}
            >
              {subtitle}
            </BannerCTASubTitle>
            {titlePosition === "center" && (
              <BannerCTATitle variant="h1">{title}</BannerCTATitle>
            )}
          </BannerCTATextWrapper>
          <BannerCTAActionsContainer>
            <BannerButton
              href={url}
              onClick={handleClick}
              label={buttonLabel}
              eventPlace={eventPlace}
              metadata={{
                title: title,
                subtitle: subtitle,
              }}
            />
          </BannerCTAActionsContainer>
        </BannerCTATextContainer>
        <BannerCTAContent>
          <Desktop>
            {videoLandscape && (
              <BannerCTAVideo
                loop
                muted
                autoPlay
                playsInline={true}
                width={videoLandscape.width}
                height={videoLandscape.height}
                poster={
                  (isWebpSupported() && imageLandscapeOptimized.webp) ||
                  imageLandscapeOptimized.jpg ||
                  imageLandscapeOptimized.optimized
                }
                isInView={isSectionInView}
                source={videoLandscapeOptimized || videoLandscape.url}
                type={videoLandscape.mimeType}
              />
            )}
            {imageLandscape && (
              <BannerCTAImageContainer
                imageUrl={
                  (isWebpSupported() && imageLandscapeOptimized.webp) ||
                  imageLandscapeOptimized.jpg ||
                  imageLandscapeOptimized.optimized
                }
              />
            )}
          </Desktop>
          <TabletAndBelow>
            {videoPortrait && (
              <BannerCTAVideo
                loop
                muted
                autoPlay
                playsInline={true}
                width={videoPortrait.width}
                height={videoPortrait.height}
                poster={
                  (isWebpSupported() && imagePortraitOptimized.webp) ||
                  imagePortraitOptimized.jpg ||
                  imagePortraitOptimized.optimized
                }
                isInView={isSectionInView}
                source={videoPortraitOptimized || videoPortrait.url}
                type={videoPortrait.mimeType}
              />
            )}
            {imagePortrait && (
              <BannerCTAImageContainer
                imageUrl={
                  (isWebpSupported() && imagePortraitOptimized.webp) ||
                  imagePortraitOptimized.jpg ||
                  imagePortraitOptimized.optimized
                }
              />
            )}
          </TabletAndBelow>
        </BannerCTAContent>
      </BannerCTAContainer>
    </BannerCTASection>
  )
})

export { LandingBannerCTA }
