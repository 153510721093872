import React from "react"

import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { ContentfulSocialProofImageEntryFieldsProps } from "../../../hooks/useLandingContentfulQuery"
import {
  SocialProofCardContainer,
  SocialProofCardImage,
} from "./LandingSocialProofCard.styled"

const LandingSocialProofCardImage = React.memo(
  (props: ContentfulSocialProofImageEntryFieldsProps) => {
    const { image } = props
    const imageOptimized = useImageOptimization(image.url)
    return (
      <SocialProofCardContainer>
        <SocialProofCardImage
          src={
            (isWebpSupported() && imageOptimized.webp) ||
            imageOptimized.jpg ||
            imageOptimized.optimized
          }
        />
      </SocialProofCardContainer>
    )
  }
)

export { LandingSocialProofCardImage }
