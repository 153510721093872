import React, { useCallback } from "react"

import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp"
import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import env from "decentraland-gatsby/dist/utils/env"
import {
  useMobileMediaQuery,
  useTabletAndBelowMediaQuery,
} from "decentraland-ui2/dist/components/Media"

import { SectionViewedTrack } from "../../../modules/segment"
import { BannerButton } from "../../Buttons/BannerButton"
import { DownloadButton } from "../../Buttons/DownloadButton"
import {
  LandingMissionsV2DetailProps,
  MissionType,
} from "./LandingMissionsV2.types"
import {
  MissionActionsContainer,
  MissionButtonBottom,
  MissionDescription,
  MissionDetailContainer,
  MissionDetailWrapper,
  MissionTextContainer,
  MissionTitle,
} from "./LandingMissionV2Detail.styled"

const LandingMissionV2Detail = React.memo(
  (props: LandingMissionsV2DetailProps) => {
    const {
      title,
      description,
      buttonLabel,
      buttonLink,
      buttonType,
      isSectionInView,
      id,
    } = props

    const handleMainCTA = useTrackLinkContext()

    const handleClick = useCallback(
      (event) => {
        event.preventDefault()
        handleMainCTA(event)
        const href = event.target.href
        setTimeout(() => {
          window.location.href = href
        }, 500)
      },
      [handleMainCTA]
    )

    const isTabletAndBelow = useTabletAndBelowMediaQuery()
    const isMobile = useMobileMediaQuery()

    return (
      <MissionDetailContainer isInView={isSectionInView}>
        <MissionDetailWrapper
          isInView={isSectionInView}
          missionId={id as MissionType}
        >
          <MissionTextContainer isInView={isSectionInView}>
            <MissionTitle variant="h2">{title}</MissionTitle>
            <MissionDescription variant="h6">
              {description.description}
            </MissionDescription>
          </MissionTextContainer>
          <MissionActionsContainer isInView={isSectionInView}>
            {buttonType === "primary" && (
              <DownloadButton
                href={env("DOWNLOAD_URL")!}
                onClick={handleClick}
                place={SectionViewedTrack.LandingMissions}
                label={"Download"}
                isFullWidth={isMobile}
              />
            )}
            {buttonType !== "primary" && (
              <BannerButton
                variant="outlined"
                color={buttonType}
                href={buttonLink}
                onClick={handleClick}
                label={buttonLabel}
                eventPlace={SectionViewedTrack.LandingMissions}
                metadata={{
                  title,
                  subSection: id,
                }}
              />
            )}
            {isTabletAndBelow && (
              <MissionButtonBottom>
                <ExpandMoreSharpIcon fontSize="inherit" />
              </MissionButtonBottom>
            )}
          </MissionActionsContainer>
        </MissionDetailWrapper>
      </MissionDetailContainer>
    )
  }
)

export { LandingMissionV2Detail }
